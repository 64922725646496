<template>
  <div>
    <TransitionGroup name="fade">
      <LoginPopup v-if="!user" @close="close" @loggedIn="close"/>
      <UseCartOption v-else-if="showCartOption" @close="close" @useCart="useCart" @startNew="startNew" />
      <StartNew v-else-if="isNewDonation" @close="close" @back="back" v-model="newDonation" @setDonation="setDonation" />
      <TheWizard v-else-if="showTheWizard" v-model="theRecurringDonation" @hideWizard="closeWizard" />
    </TransitionGroup>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    UseCartOption: defineAsyncComponent(() => import('./views/UseCartOption.vue')),
    StartNew: defineAsyncComponent(() => import('./views/StartNew.vue')),
    TheWizard: defineAsyncComponent(() => import('./views/za/views/TheWizard.vue'))
  },
  name: 'SetupRecurringDonations',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      showCartOption: false,
      isNewDonation: false,
      showTheWizard: false,
      usingCart: false,
      newDonation: {
        amount: null,
        currency: null,
        projectId: '',
        donationTypes: []
      },
      theRecurringDonation: {
        donorBankAccount: {
          bankName: '',
          accountHolderName: '',
          branchCode: '',
          accountNumber: ''
        },
        startDate: 0,
        frequency: '',
        reference: '',
        debitOrderDay: 0,
        dailySadaqaDonation: false,
        recurringDonationType: '',
        untilCancelled: true,
        numberOfPayments: 0,
        transactionDTO: {
          donationDtoList: [],
          userID: '',
          organisationID: 0,
          sponsored: null
        }
      }
    }
  },
  mounted () {
    this.newDonation.currency = this.defaultCurrency
    this.checkCartItems()
    this.setDonor()
  },
  watch: {
    donateAs: {
      deep: true,
      handler (val) {
        this.setDonor()
      }
    }
  },
  computed: {
    ...mapGetters([
      'cart', 'donateAs', 'user'
    ]),
    donatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.donateAs.organisationID) {
          return 'organisation'
        } else {
          return 'individual'
        }
      } else {
        return 'individual'
      }
    }
  },
  methods: {
    close () {
      console.log('CLOSE')
      this.$emit('close')
    },
    checkCartItems () {
      if (this.cart && this.cart.length > 0) {
        this.showCartOption = true
      } else {
        this.isNewDonation = true
      }
    },
    hideCartOption () {
      this.showCartOption = false
    },
    setDonor () {
      if (this.donateAs) {
        if (this.donatingType === 'organisation') {
          this.theRecurringDonation.transactionDTO.organisationID = this.donateAs.organisationID
          this.theRecurringDonation.transactionDTO.userID = this.donateAs.userID
        } else {
          this.theRecurringDonation.transactionDTO.organisationID = 0
          this.theRecurringDonation.transactionDTO.userID = this.donateAs.userID
        }
      }
    },
    async useCart () {
      console.log('useCart')
      this.usingCart = true
      this.theRecurringDonation.transactionDTO.donationDtoList = []
      await this.addCartToDonation()
      await this.hideCartOption()
      await this.showWizard()
    },
    addCartToDonation () {
      let currencyID = 1
      if (this.cart[0].currency !== 'R') {
        currencyID = 3
      }
      this.cart.forEach(item => {
        let obj = {}
        item.donationTypes.forEach(type => {
          if (type.amount && Number(type.amount) > 0) {
            const donationTypeID = type.type ? type.type.donationType.donationTypeID : null
            obj = {
              amount: Number(type.amount),
              donationTypeID: donationTypeID,
              projectID: item.projectId ? item.projectId : null,
              currencyId: currencyID
            }
            this.theRecurringDonation.transactionDTO.donationDtoList.push(obj)
          }
        })
      })
    },
    addDonation () {
      let currencyID = 1
      if (this.newDonation.currency !== 'R') {
        currencyID = 3
      }
      let obj = {}
      this.newDonation.donationTypes.forEach(type => {
        if (type.amount && Number(type.amount) > 0) {
          const donationTypeID = type.type ? type.type.donationType.donationTypeID : null
          obj = {
            amount: Number(type.amount),
            donationTypeID: donationTypeID,
            projectID: this.newDonation.projectId ? this.newDonation.projectId : null,
            currencyId: currencyID
          }
          this.theRecurringDonation.transactionDTO.donationDtoList.push(obj)
        }
      })
    },
    startNew () {
      console.log('startNew')
      this.usingCart = false
      this.hideCartOption()
      this.isNewDonation = true
    },
    async setDonation () {
      console.log('setDonation', this.newDonation)
      this.theRecurringDonation.transactionDTO.donationDtoList = []
      await this.addDonation()
      this.showWizard()
    },
    showWizard () {
      this.isNewDonation = false
      this.showTheWizard = true
    },
    closeWizard () {
      this.showTheWizard = false
      this.checkCartItems()
    },
    back () {
      this.isNewDonation = false
      if (this.cart && this.cart.length > 0) {
        this.showCartOption = true
      } else {
        this.close()
      }
    }
  }
}
</script>
